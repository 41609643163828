import DetailPaymentHandler from '../../handlers/detail-payment.handler';
import { line } from '../templates/components';
import { iconChevronLeft } from '../templates/icons';

class DetailPayment extends HTMLElement {
  connectedCallback() {
    this.render();
    this.handler();
  }

  render() {
    this.innerHTML = `
      <div id="detail-container" class="m-5 lg:w-2/5 lg:mx-auto">
        <div class="mb-5 flex">
          <button class="pr-4" onclick="location.assign('#')">${iconChevronLeft}</button>
          <p>Detail payment</p>
        </div>
        <div id="container-detail-student"></div>${line}
        <div id="container-detail-payment"></div>
        <div id="container-detail-officer"></div>
        <div id="container-action-detail-btn" class="mt-10"></div>
        <div id="container-popup" class="absolute top-1/3 right-5 left-5 z-40 p-5 lg:w-2/5 lg:mx-auto text-center shadow-[0_4px_12px_#3572EF] bg-white rounded-xl hidden"></div>
      </div>
    `;
  }

  handler() {
    const detailContainer = this.querySelector('#detail-container');
    const containerDetailStudent = this.querySelector('#container-detail-student');
    const containerDetailPayment = this.querySelector('#container-detail-payment');
    const containerDetailOfficer = this.querySelector('#container-detail-officer');
    const containerActionDetailBtn = this.querySelector('#container-action-detail-btn');
    DetailPaymentHandler.init({
      detailContainer,
      containerDetailStudent,
      containerDetailPayment,
      containerDetailOfficer,
      containerActionDetailBtn,
    });
  }
}

customElements.define('detail-payment', DetailPayment);
const detailPayment = '<detail-payment></detail-payment>';
export default detailPayment;
