import CreateRequestHandler from '../../handlers/create-request.handler';
import { iconChevronLeft } from '../templates/icons';

class CreateRequest extends HTMLElement {
  connectedCallback() {
    this.render();
    this.handler();
  }

  render() {
    this.innerHTML = `
      <div id="create-request-container" class="m-5 lg:w-2/5 lg:mx-auto">
        <div class="mb-5 flex">
          <button class="pr-4" onclick="location.assign('#')">${iconChevronLeft}</button>
          <p>Create request</p>
        </div>
        <div id="container-request-form"></div>
        <p id="container-request-message" class="mt-2 text-center"></p>
        <div id="container-load"></div>
      </div>
    `;
  }

  handler() {
    const createRequestContainer = this.querySelector('#create-request-container');
    const containerRequestForm = this.querySelector('#container-request-form');
    const containerRequestMessage = this.querySelector('#container-request-message');
    const containerLoad = this.querySelector('#container-load');
    CreateRequestHandler.init({
      createRequestContainer,
      containerRequestForm,
      containerRequestMessage,
      containerLoad,
    });
  }
}

customElements.define('create-request', CreateRequest);
const createRequest = '<create-request></create-request>';
export default createRequest;
