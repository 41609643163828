import { buttonFilled } from '../templates/components';

class Error extends HTMLElement {
  connectedCallback() {
    this.render();
    this._insertComponent();
  }

  render() {
    this.innerHTML = `
      <div id="container-error" class="m-5 -mt-20 h-screen flex flex-col justify-center lg:w-2/5 lg:mx-auto text-center">
        <video src="./assets/images/not-found.webm" width="200px" height="200px" class="mx-auto mb-5" autoplay loop muted playsinline></video>
        <p class="text-2xl">Page Not found!</p>
      </div>
    `;
  }

  _insertComponent() {
    const containerError = this.querySelector('#container-error');
    containerError.innerHTML += buttonFilled('back-home-btn', 'Back to home', 'mt-20');
    const backHomeBtn = this.querySelector('#back-home-btn');
    backHomeBtn.addEventListener('click', () => location.assign('#'));
  }
}

customElements.define('error-page', Error);
const error = '<error-page></error-page>';
export default error;
